import { Injectable, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngxs/store'
import { BehaviorSubject, Subject, fromEvent } from 'rxjs'
import { debounceTime, takeUntil } from 'rxjs/operators'
import { RolesType } from 'src/enums/roles.enum'
import { environment } from 'src/environments/environment'
import { UserMetaState } from './../../../store/user/user.meta.state'

// Menu
export interface Menu {
  headTitle1?: string
  headTitle2?: string
  path?: string
  title?: string
  icon?: string
  type?: string
  badgeType?: string
  badgeValue?: string
  active?: boolean
  bookmark?: boolean
  children?: Menu[]
  allowedRoles?: RolesType[]
  availableInProduction?: boolean
}

@Injectable({
  providedIn: 'root',
})
export class NavService implements OnDestroy {
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
    window.innerWidth,
  )
  // Mega Menu
  public megaMenu: boolean = false
  public levelMenu: boolean = false
  public megaMenuColapse: boolean = window.innerWidth < 1199
  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth >= 991
  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991
  public hoverSidebar: boolean = window.innerWidth < 991
  public mouseTrigger: boolean
  // Full screen
  public fullScreen: boolean = false

  allRoleTypes = Object.values(RolesType)

  isMenuItemsForMobile = false

  removeSomeRoles(rolesToRemove: RolesType[]): RolesType[] {
    return this.allRoleTypes.filter((role) => !rolesToRemove.includes(role))
  }

  updateMenuItem(menuItem: Partial<Menu>): void {
    const menuItems = this.MENUITEMS.map((item) => {
      if (item.title === menuItem.title) {
        return { ...item, ...menuItem }
      }
      return item
    })
    this.getItemsNextValue(menuItems)
  }

  setMenuItemsForMobileApp(): Menu[] {
    const allowedMenuItemsForMobileApp: Menu[] = [
      {
        headTitle1: '',
        headTitle2: '',
      },
      {
        title: 'Home',
        icon: 'icon-home',
        type: 'link',
        path: '/home',
        allowedRoles: this.removeSomeRoles([RolesType.TEMPORARY_ACCESS]),
      },
      {
        title: 'Proposals',
        icon: 'icon-control',
        type: 'sub',
        children: [
          {
            title: 'Standard',
            type: 'link',
            path: '/ecommerce/standard',
            allowedRoles: this.removeSomeRoles([
              RolesType.TEMPORARY_ACCESS,
              RolesType.MANAGEMENT,
            ]),
          },
          {
            title: 'Simulation',
            type: 'link',
            path: '/proposal/simulation',
            allowedRoles: this.removeSomeRoles([RolesType.TEMPORARY_ACCESS]),
          },
          { title: 'Control', type: 'link', path: '/proposal/control' },
        ],
        allowedRoles: [
          RolesType.ADMIN,
          RolesType.MANAGEMENT,
          RolesType.REGIONAL_COORDINATOR,
          RolesType.REPRESENTATIVE,
          RolesType.SELLER_BYD,
          RolesType.BUSINESS_1,
          RolesType.BUSINESS_2,
          RolesType.BACKOFFICE,
          RolesType.PARTNER,
          RolesType.INTEGRATOR,
          RolesType.DISTRIBUTOR,
          RolesType.END_CLIENT,
        ],
      },
      {
        title: 'PO',
        icon: 'icon-po',
        type: 'link',
        path: '/proposal/po',
        allowedRoles: this.removeSomeRoles([RolesType.TEMPORARY_ACCESS]),
      },
      {
        title: 'My Clients',
        icon: 'icon-user',
        type: 'link',
        path: '/client/list',
        allowedRoles: this.removeSomeRoles([
          RolesType.END_CLIENT,
          RolesType.PCP,
          RolesType.LOGISTIC,
          RolesType.TEMPORARY_ACCESS,
        ]),
      },
    ]
    this.items.next(allowedMenuItemsForMobileApp)
    this.isMenuItemsForMobile = true
    return allowedMenuItemsForMobileApp
  }

  MENUITEMS: Menu[] = [
    {
      headTitle1: '',
      headTitle2: '',
    },
    {
      title: 'Home',
      icon: 'icon-home',
      type: 'link',
      path: '/home',
      allowedRoles: this.removeSomeRoles([RolesType.TEMPORARY_ACCESS]),
    },
    {
      title: 'Heat Map',
      icon: 'icon-heat-map',
      type: 'link',
      path: '/home/heat-map',
      allowedRoles: this.removeSomeRoles([
        RolesType.TEMPORARY_ACCESS,
        RolesType.ENGINEERING,
      ]),
    },
    {
      title: 'Proposals',
      icon: 'icon-control',
      type: 'sub',
      children: [
        {
          title: 'Customization',
          type: 'link',
          path: '/proposal/customization',
          allowedRoles: this.removeSomeRoles([
            RolesType.TEMPORARY_ACCESS,
            RolesType.MANAGEMENT,
            RolesType.DEALER,
            RolesType.ENGINEERING,
          ]),
        },
        {
          title: 'Standard',
          type: 'link',
          path: '/ecommerce/standard',
          allowedRoles: this.removeSomeRoles([
            RolesType.TEMPORARY_ACCESS,
            RolesType.MANAGEMENT,
            RolesType.DEALER,
            RolesType.ENGINEERING,
          ]),
        },
        {
          title: 'Chargers',
          type: 'link',
          path: '/ecommerce/chargers',
          allowedRoles: this.removeSomeRoles([
            RolesType.TEMPORARY_ACCESS,
            RolesType.MANAGEMENT,
            RolesType.ENGINEERING,
          ]),
        },
        {
          title: 'Simulation',
          type: 'link',
          path: '/proposal/simulation',
          allowedRoles: this.removeSomeRoles([
            RolesType.TEMPORARY_ACCESS,
            RolesType.ENGINEERING,
          ]),
          availableInProduction: true,
        },
        { title: 'Control', type: 'link', path: '/proposal/control' },
      ],
      allowedRoles: [
        RolesType.ADMIN,
        RolesType.MANAGEMENT,
        RolesType.REGIONAL_COORDINATOR,
        RolesType.REPRESENTATIVE,
        RolesType.SELLER_BYD,
        RolesType.BUSINESS_1,
        RolesType.BUSINESS_2,
        RolesType.BACKOFFICE,
        RolesType.PARTNER,
        RolesType.INTEGRATOR,
        RolesType.DEALER,
      ],
    },
    {
      title: 'PO',
      icon: 'icon-po',
      type: 'link',
      path: '/proposal/po',
      allowedRoles: this.removeSomeRoles([
        RolesType.TEMPORARY_ACCESS,
        RolesType.ENGINEERING,
      ]),
    },
    {
      title: 'Offer',
      icon: 'icon-po',
      type: 'link',
      path: '/offer',
      allowedRoles: [
        RolesType.BUSINESS_1,
        RolesType.BUSINESS_2,
        RolesType.ADMIN,
        RolesType.DEALER,
        RolesType.INTEGRATOR,
      ],
    },
    {
      title: 'Wallet',
      icon: 'icon-credit',
      type: 'link',
      path: '/wallet',
      allowedRoles: [RolesType.DEALER],
    },
    /*    {
      title: 'Chat',
      icon: 'icon-chat',
      type: 'link',
      path: '/chat',
      badgeType: 'primary',
      badgeValue: '',
      allowedRoles: [
        RolesType.ADMIN,
        RolesType.MANAGEMENT,
        RolesType.REGIONAL_COORDINATOR,
        RolesType.SELLER_BYD,
        RolesType.BUSINESS_1,
        RolesType.BUSINESS_2,
        RolesType.BACKOFFICE,
        RolesType.PCP,
        RolesType.LOGISTIC,
      ],
    },*/
    {
      title: 'BYD Auto',
      icon: 'icon-byd-auto',
      type: 'extLink',
      path: 'https://www.bydcars.com.br/',
      allowedRoles: this.removeSomeRoles([
        RolesType.TEMPORARY_ACCESS,
        RolesType.ENGINEERING,
      ]),
    },
    {
      title: 'My Clients',
      icon: 'icon-user',
      type: 'link',
      path: '/client/list',
      allowedRoles: this.removeSomeRoles([
        RolesType.END_CLIENT,
        RolesType.PCP,
        RolesType.LOGISTIC,
        RolesType.TEMPORARY_ACCESS,
        RolesType.ENGINEERING,
      ]),
    },
    {
      title: 'Users',
      icon: 'icon-users',
      type: 'link',
      path: '/user/list',
      allowedRoles: [
        RolesType.ADMIN,
        RolesType.BUSINESS_1,
        RolesType.BUSINESS_2,
        RolesType.BACKOFFICE,
        RolesType.SELLER_BYD,
      ],
    },
    {
      title: 'Coupons',
      icon: 'icon-coupons',
      type: 'link',
      path: '/coupon/list',
      allowedRoles: [
        RolesType.ADMIN,
        RolesType.BUSINESS_1,
        RolesType.BUSINESS_2,
        RolesType.BACKOFFICE,
      ],
    },
    {
      title: 'Products',
      type: 'link',
      icon: 'icon-product',
      path: '/products/list',
      allowedRoles: [
        RolesType.ADMIN,
        RolesType.BUSINESS_1,
        RolesType.BUSINESS_2,
        RolesType.ENGINEERING,
      ],
    },
    {
      title: 'Kits',
      type: 'link',
      icon: 'icon-kits',
      path: '/kits/list',
      allowedRoles: [
        RolesType.ADMIN,
        RolesType.BUSINESS_1,
        RolesType.BUSINESS_2,
      ],
    },
  ]

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS)
  private unsubscriber: Subject<any> = new Subject()

  constructor(
    private router: Router,
    private store: Store,
  ) {
    this.updateAllowedMenuItems()
    this.setScreenWidth(window.innerWidth)
    fromEvent(window, 'resize')
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth)
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true
          this.megaMenu = false
          this.levelMenu = false
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true
        }
      })
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true
        this.megaMenu = false
        this.levelMenu = false
      })
    }
  }

  ngOnDestroy() {
    this.unsubscriber.complete()
  }

  updateAllowedMenuItems() {
    this.getItemsNextValue(this.MENUITEMS)
  }

  getItemsNextValue(menuItems: Menu[]): void {
    if (!menuItems || menuItems?.length === 0) {
      menuItems = this.MENUITEMS
    }
    const user = this.store.selectSnapshot(UserMetaState.getUser)
    if (user) {
      const allowedMenuItems = this.getAllowedMenuItems(user.roles, menuItems)
      if (!environment.production) {
        this.items.next(allowedMenuItems)
      } else {
        const availableInProductionMenuItems =
          this.removeItemsAndChildrenNotAvailableInProduction(allowedMenuItems)
        this.items.next(availableInProductionMenuItems)
      }

      // Nav for mobile (older commit)
      // if (this.isMenuItemsForMobile) {
      //   this.setMenuItemsForMobileApp()
      //   return
      // }
      // const allowedMenuItems = this.getAllowedMenuItems(user.roles, this.MENUITEMS)
      // this.items.next(allowedMenuItems)
    }
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width)
  }

  getAllowedMenuItems(roles: RolesType[], menuItems = this.MENUITEMS): Menu[] {
    const allowedItems = []
    menuItems.forEach((item) => {
      if (item.allowedRoles?.some((role) => roles.includes(role))) {
        const allowedObject = { ...item }
        if (allowedObject.children) {
          allowedObject.children = allowedObject.children.filter((child) => {
            if (child.allowedRoles) {
              return child?.allowedRoles?.some((role) => roles.includes(role))
            }
            return true
          })
        }
        allowedItems.push(allowedObject)
      }
    })
    return allowedItems
  }

  removeItemsAndChildrenNotAvailableInProduction(menuItems: Menu[]): Menu[] {
    const allowedItems = []
    menuItems.forEach((item) => {
      if (
        item?.availableInProduction !== false ||
        item?.children?.some((child) => child?.availableInProduction !== false)
      ) {
        const allowedObject = { ...item }
        if (allowedObject.children) {
          allowedObject.children = allowedObject.children.filter(
            (child) => child.availableInProduction !== false,
          )
        }
        allowedItems.push(allowedObject)
      }
    })
    return allowedItems
  }
}
