import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Store } from '@ngxs/store'
import { Observable } from 'rxjs'
import { Utils } from 'src/helpers/utils.helper'
import { InterceptorSkipHeader } from 'src/interceptors/token.interceptor'
import { SetCurrentUserAction } from 'src/store/user/user.meta.actions'
import { UserMetaState } from 'src/store/user/user.meta.state'
import { environment } from '../../../environments/environment'
import {
  ILoginBody,
  ILoginResponse,
  ISignUp,
} from '../../../interfaces/auth/auth.interface'
import { NavService } from './nav.service'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  baseRoute = `${environment.api}/login`
  constructor(
    private http: HttpClient,
    private utils: Utils,
    private store: Store,
    private navService: NavService,
  ) {}

  login(loginData: ILoginBody) {
    return this.http.post<ILoginResponse>(`${this.baseRoute}`, loginData, {
      headers: InterceptorSkipHeader,
    })
  }

  retrieveUser(): void {
    const user = localStorage.getItem('user')
    if (user) {
      const parsedUser = JSON.parse(user)
      this.store.dispatch(new SetCurrentUserAction(parsedUser))
      const currentRoute = this.utils.getCurrentRoute()
      if (currentRoute === '/auth/login') {
        this.utils.navigateTo('/home')
      }
      const userDeviceInfo = this.store.selectSnapshot(
        UserMetaState.getUserDeviceInfo,
      )
      if (userDeviceInfo.platform === 'web') {
        this.navService.updateAllowedMenuItems()
      }
    } else {
      this.utils.navigateTo('/auth/login')
    }
  }

  refreshToken(): Observable<ILoginResponse> {
    return this.http.post<ILoginResponse>(`${environment.api}/refreshToken`, {})
  }

  signUp(signUpData: ISignUp) {
    return this.http.post(`${environment.api}/users/register`, signUpData, {
      headers: InterceptorSkipHeader,
    })
  }

  passwordRecovery(email: string) {
    return this.http.post(
      `${environment.api}/recovery`,
      { email },
      {
        headers: InterceptorSkipHeader,
      },
    )
  }
}
